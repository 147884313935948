// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import TsiPng from "/images/ranks/tsi.png";
import CookPng from "/images/ranks/cook.png";
import FitterPng from "/images/ranks/fitter.png";
import MasterPng from "/images/ranks/master.png";
import MessmanPng from "/images/ranks/messman.png";
import Deck_ratingPng from "/images/ranks/deck_rating.png";
import Chief_officerPng from "/images/ranks/chief_officer.png";
import Engine_ratingPng from "/images/ranks/engine_rating.png";
import Third_officerPng from "/images/ranks/third_officer.png";
import Chief_engineerPng from "/images/ranks/chief_engineer.png";
import Second_officerPng from "/images/ranks/second_officer.png";
import Third_engineerPng from "/images/ranks/third_engineer.png";
import Eto_electricianPng from "/images/ranks/eto_electrician.png";
import Fourth_engineerPng from "/images/ranks/fourth_engineer.png";
import Second_engineerPng from "/images/ranks/second_engineer.png";

function roleRankImage(rank) {
  if (rank === "DECK_RATING") {
    return Deck_ratingPng;
  } else if (rank === "FOURTH_ENGINEER") {
    return Fourth_engineerPng;
  } else if (rank === "MASTER") {
    return MasterPng;
  } else if (rank === "SECOND_ENGINEER" || rank === "CARGO_GAS_ENGINEER") {
    return Second_engineerPng;
  } else if (rank === "ENGINE_RATING") {
    return Engine_ratingPng;
  } else if (rank === "TSI") {
    return TsiPng;
  } else if (rank === "CHIEF_OFFICER") {
    return Chief_officerPng;
  } else if (rank === "SECOND_OFFICER") {
    return Second_officerPng;
  } else if (rank === "THIRD_ENGINEER") {
    return Third_engineerPng;
  } else if (rank === "MESSMAN") {
    return MessmanPng;
  } else if (rank === "CHIEF_ENGINEER") {
    return Chief_engineerPng;
  } else if (rank === "COOK") {
    return CookPng;
  } else if (rank === "FITTER") {
    return FitterPng;
  } else if (rank === "ETO_ELECTRICIAN") {
    return Eto_electricianPng;
  } else {
    return Third_officerPng;
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var rank = vnode.attrs.rank;
                var style = vnode.attrs.style;
                var className = vnode.attrs.className;
                return ReactDOM.jsx("img", {
                            className: className,
                            style: style,
                            src: roleRankImage(rank)
                          });
              }));
}

export {
  make ,
}
/* Mithril Not a pure module */
