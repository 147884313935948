// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as UserProfile from "../UserProfile.js";
import * as ButtonFilterModal from "../../common/components/ButtonFilterModal.js";
import * as FilterModalContainer from "../../common/components/FilterModalContainer.js";

function rankActive(state) {
  if (state.master || state.chief_officer || state.second_officer || state.third_officer || state.chief_engineer || state.second_engineer || state.third_engineer || state.fourth_engineer || state.deck_rating || state.engine_rating || state.eto_electrician || state.cargo_gas_engineer || state.fitter || state.cook) {
    return true;
  } else {
    return state.messman;
  }
}

function statusActive(state) {
  if (state.active) {
    return true;
  } else {
    return state.inactive;
  }
}

function anyFilter(state) {
  if (rankActive(state)) {
    return true;
  } else {
    return statusActive(state);
  }
}

function isOn(filter, state) {
  if (filter === "DECK_RATING") {
    return state.deck_rating;
  } else if (filter === "FOURTH_ENGINEER") {
    return state.fourth_engineer;
  } else if (filter === "inactive") {
    return state.inactive;
  } else if (filter === "MASTER") {
    return state.master;
  } else if (filter === "CARGO_GAS_ENGINEER") {
    return state.cargo_gas_engineer;
  } else if (filter === "ENGINE_RATING") {
    return state.engine_rating;
  } else if (filter === "CHIEF_OFFICER") {
    return state.chief_officer;
  } else if (filter === "SECOND_OFFICER") {
    return state.second_officer;
  } else if (filter === "active") {
    return state.active;
  } else if (filter === "THIRD_ENGINEER") {
    return state.third_engineer;
  } else if (filter === "MESSMAN") {
    return state.messman;
  } else if (filter === "CHIEF_ENGINEER") {
    return state.chief_engineer;
  } else if (filter === "SECOND_ENGINEER") {
    return state.second_engineer;
  } else if (filter === "COOK") {
    return state.cook;
  } else if (filter === "FITTER") {
    return state.fitter;
  } else if (filter === "ETO_ELECTRICIAN") {
    return state.eto_electrician;
  } else {
    return state.third_officer;
  }
}

function activeFilters(state) {
  var filtersOn = [];
  if (state.master) {
    filtersOn.push("MASTER");
  }
  if (state.chief_officer) {
    filtersOn.push("CHIEF_OFFICER");
  }
  if (state.second_officer) {
    filtersOn.push("SECOND_OFFICER");
  }
  if (state.third_officer) {
    filtersOn.push("THIRD_OFFICER");
  }
  if (state.chief_engineer) {
    filtersOn.push("CHIEF_ENGINEER");
  }
  if (state.second_engineer) {
    filtersOn.push("SECOND_ENGINEER");
  }
  if (state.third_engineer) {
    filtersOn.push("THIRD_ENGINEER");
  }
  if (state.fourth_engineer) {
    filtersOn.push("FOURTH_ENGINEER");
  }
  if (state.deck_rating) {
    filtersOn.push("DECK_RATING");
  }
  if (state.engine_rating) {
    filtersOn.push("ENGINE_RATING");
  }
  if (state.eto_electrician) {
    filtersOn.push("ETO_ELECTRICIAN");
  }
  if (state.cargo_gas_engineer) {
    filtersOn.push("CARGO_GAS_ENGINEER");
  }
  if (state.fitter) {
    filtersOn.push("FITTER");
  }
  if (state.cook) {
    filtersOn.push("COOK");
  }
  if (state.messman) {
    filtersOn.push("MESSMAN");
  }
  if (state.active) {
    filtersOn.push("active");
  }
  if (state.inactive) {
    filtersOn.push("inactive");
  }
  return filtersOn;
}

function toString(filter) {
  if (filter === "inactive") {
    return "Inactive";
  } else if (filter === "active") {
    return "Active";
  } else {
    return UserProfile.Rank.toString(filter);
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs;
                var onClose = match.onClose;
                var filterUpdate = match.filterUpdate;
                var disabledFilters = match.disabledFilters;
                var filters = match.filters;
                var toButtonFilter = function (filter) {
                  var onTheme = ButtonFilterModal.Theme.on;
                  var offTheme = ButtonFilterModal.Theme.off;
                  return React.jsx(ButtonFilterModal.make, {
                              text: toString(filter),
                              onClick: (function (_event) {
                                  filterUpdate(filter, !isOn(filter, filters));
                                }),
                              disabled: isOn(filter, disabledFilters),
                              theme: isOn(filter, filters) ? onTheme : offTheme
                            });
                };
                var rankButtonElements = UserProfile.Ranks.sortedCrew.map(function (r) {
                      return ReactDOM.jsx("div", {
                                  children: toButtonFilter(r),
                                  className: "tw-flex tw-flex-row"
                                });
                    });
                return React.jsx(FilterModalContainer.make, {
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("div", {
                                                  children: ReactDOM.jsx("label", {
                                                        children: "Rank:",
                                                        className: "tw-font-medium"
                                                      }),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            rankButtonElements
                                          ],
                                          className: "tw-flex tw-flex-col tw-justify-start tw-gap-2"
                                        }),
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("div", {
                                                  children: ReactDOM.jsx("label", {
                                                        children: "Status:",
                                                        className: "tw-font-medium"
                                                      }),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsxs("div", {
                                                  children: [
                                                    toButtonFilter("active"),
                                                    toButtonFilter("inactive")
                                                  ],
                                                  className: "tw-flex tw-flex-row tw-justify-start tw-gap-2"
                                                })
                                          ],
                                          className: "tw-flex tw-flex-col tw-justify-start tw-gap-2"
                                        })
                                  ],
                                  className: "tw-flex tw-flex-col tw-justify-start tw-gap-4"
                                }),
                            showModal: match.showModal,
                            textApplyButton: "Apply",
                            onClear: match.onClear,
                            onClose: onClose,
                            onApplyFilter: onClose
                          });
              }));
}

var Filter_defaultTrue = {
  master: true,
  chief_officer: true,
  second_officer: true,
  third_officer: true,
  chief_engineer: true,
  second_engineer: true,
  third_engineer: true,
  fourth_engineer: true,
  deck_rating: true,
  engine_rating: true,
  eto_electrician: true,
  cargo_gas_engineer: true,
  fitter: true,
  cook: true,
  messman: true,
  active: true,
  inactive: true
};

var Filter_defaultFalse = {
  master: false,
  chief_officer: false,
  second_officer: false,
  third_officer: false,
  chief_engineer: false,
  second_engineer: false,
  third_engineer: false,
  fourth_engineer: false,
  deck_rating: false,
  engine_rating: false,
  eto_electrician: false,
  cargo_gas_engineer: false,
  fitter: false,
  cook: false,
  messman: false,
  active: false,
  inactive: false
};

var Filter = {
  defaultTrue: Filter_defaultTrue,
  defaultFalse: Filter_defaultFalse,
  rankActive: rankActive,
  statusActive: statusActive,
  anyFilter: anyFilter,
  isOn: isOn,
  activeFilters: activeFilters,
  toString: toString
};

export {
  Filter ,
  make ,
}
/* React Not a pure module */
