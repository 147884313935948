// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ProfileId from "./ProfileId.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";

var _map = {"MASTER":"MASTER","CHIEF_OFFICER":"CHIEF_OFFICER","SECOND_OFFICER":"SECOND_OFFICER","THIRD_OFFICER":"THIRD_OFFICER","CHIEF_ENGINEER":"CHIEF_ENGINEER","SECOND_ENGINEER":"SECOND_ENGINEER","THIRD_ENGINEER":"THIRD_ENGINEER","FOURTH_ENGINEER":"FOURTH_ENGINEER","DECK_RATING":"DECK_RATING","ENGINE_RATING":"ENGINE_RATING","ETO_ELECTRICIAN":"ETO_ELECTRICIAN","CARGO_GAS_ENGINEER":"CARGO_GAS_ENGINEER","FITTER":"FITTER","COOK":"COOK","MESSMAN":"MESSMAN"};

function crewToJs(param) {
  return param;
}

function crewFromJs(param) {
  return _map[param];
}

var _map$1 = {"TSI":"TSI"};

function shoreToJs(param) {
  return param;
}

function shoreFromJs(param) {
  return _map$1[param];
}

function toString(rank) {
  if (rank === "DECK_RATING") {
    return "Deck Rating";
  } else if (rank === "FOURTH_ENGINEER") {
    return "Fourth Engineer";
  } else if (rank === "MASTER") {
    return "Master";
  } else if (rank === "CARGO_GAS_ENGINEER") {
    return "Cargo/Gas Engineer";
  } else if (rank === "ENGINE_RATING") {
    return "Engine Rating";
  } else if (rank === "TSI") {
    return "TSI";
  } else if (rank === "CHIEF_OFFICER") {
    return "Chief Officer";
  } else if (rank === "SECOND_OFFICER") {
    return "Second Officer";
  } else if (rank === "THIRD_ENGINEER") {
    return "Third Engineer";
  } else if (rank === "MESSMAN") {
    return "Messman";
  } else if (rank === "CHIEF_ENGINEER") {
    return "Chief Engineer";
  } else if (rank === "SECOND_ENGINEER") {
    return "Second Engineer";
  } else if (rank === "COOK") {
    return "Cook";
  } else if (rank === "FITTER") {
    return "Fitter";
  } else if (rank === "ETO_ELECTRICIAN") {
    return "ETO/Electrician";
  } else {
    return "Third Officer";
  }
}

function fromString(rank) {
  switch (rank) {
    case "Cargo/Gas Engineer" :
        return "CARGO_GAS_ENGINEER";
    case "Chief Engineer" :
        return "CHIEF_ENGINEER";
    case "Chief Officer" :
        return "CHIEF_OFFICER";
    case "Cook" :
        return "COOK";
    case "Deck Rating" :
        return "DECK_RATING";
    case "ETO/Electrician" :
        return "ETO_ELECTRICIAN";
    case "Engine Rating" :
        return "ENGINE_RATING";
    case "Fitter" :
        return "FITTER";
    case "Fourth Engineer" :
        return "FOURTH_ENGINEER";
    case "Messman" :
        return "MESSMAN";
    case "Second Engineer" :
        return "SECOND_ENGINEER";
    case "Second Officer" :
        return "SECOND_OFFICER";
    case "TSI" :
        return "TSI";
    case "Third Engineer" :
        return "THIRD_ENGINEER";
    case "Third Officer" :
        return "THIRD_OFFICER";
    default:
      return "MASTER";
  }
}

function toCompareValue(rank) {
  if (rank === "DECK_RATING") {
    return 400;
  } else if (rank === "FOURTH_ENGINEER") {
    return 700;
  } else if (rank === "MASTER") {
    return 1500;
  } else if (rank === "CARGO_GAS_ENGINEER") {
    return 900;
  } else if (rank === "ENGINE_RATING") {
    return 300;
  } else if (rank === "TSI") {
    return 1600;
  } else if (rank === "CHIEF_OFFICER") {
    return 1400;
  } else if (rank === "SECOND_OFFICER") {
    return 1300;
  } else if (rank === "THIRD_ENGINEER") {
    return 800;
  } else if (rank === "MESSMAN") {
    return 100;
  } else if (rank === "CHIEF_ENGINEER") {
    return 1100;
  } else if (rank === "SECOND_ENGINEER") {
    return 1000;
  } else if (rank === "COOK") {
    return 200;
  } else if (rank === "FITTER") {
    return 500;
  } else if (rank === "ETO_ELECTRICIAN") {
    return 600;
  } else {
    return 1200;
  }
}

function compareRank(rankA, rankB) {
  return Math.imul(-1, Caml.int_compare(toCompareValue(rankA), toCompareValue(rankB)));
}

function tFromJs(x) {
  return Core__Option.orElse(_map[x], _map$1[x]);
}

var deserFromJS = $$JSON.Field.variadicString("Expected a valid Rank", (function (x) {
        return tFromJs(x);
      }));

var Rank = {
  crewToJs: crewToJs,
  crewFromJs: crewFromJs,
  shoreToJs: shoreToJs,
  shoreFromJs: shoreFromJs,
  toString: toString,
  fromString: fromString,
  toCompareValue: toCompareValue,
  compareRank: compareRank,
  tFromJs: tFromJs,
  deserFromJS: deserFromJS
};

var all = [
  "TSI",
  "MASTER",
  "CHIEF_OFFICER",
  "SECOND_OFFICER",
  "THIRD_OFFICER",
  "CHIEF_ENGINEER",
  "SECOND_ENGINEER",
  "THIRD_ENGINEER",
  "FOURTH_ENGINEER",
  "DECK_RATING",
  "ENGINE_RATING",
  "ETO_ELECTRICIAN",
  "CARGO_GAS_ENGINEER",
  "FITTER",
  "COOK",
  "MESSMAN"
];

var crew = [
  "MASTER",
  "CHIEF_OFFICER",
  "SECOND_OFFICER",
  "THIRD_OFFICER",
  "CHIEF_ENGINEER",
  "SECOND_ENGINEER",
  "THIRD_ENGINEER",
  "FOURTH_ENGINEER",
  "DECK_RATING",
  "ENGINE_RATING",
  "ETO_ELECTRICIAN",
  "CARGO_GAS_ENGINEER",
  "FITTER",
  "COOK",
  "MESSMAN"
];

var sortedAll = Prelude.$$Array.sort(all, (function (r) {
        return toCompareValue(r);
      }), true);

var sortedCrew = Prelude.$$Array.sort(crew, (function (r) {
        return toCompareValue(r);
      }), true);

var Ranks = {
  all: all,
  crew: crew,
  sortedAll: sortedAll,
  sortedCrew: sortedCrew
};

var _map$2 = {"active":"active","inactive":"inactive","archived":"archived"};

function tToJs(param) {
  return param;
}

function tFromJs$1(param) {
  return _map$2[param];
}

var all$1 = [
  "active",
  "inactive",
  "archived"
];

function toOptionText(state) {
  if (state === "archived") {
    return "Permanently remove profile";
  } else if (state === "active") {
    return "Active";
  } else {
    return "Inactive";
  }
}

var State = {
  tToJs: tToJs,
  tFromJs: tFromJs$1,
  all: all$1,
  toOptionText: toOptionText
};

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function name(profile) {
  var match = profile.first_name;
  var match$1 = profile.last_name;
  if (match === "") {
    if (match$1 === "") {
      return ;
    } else {
      return match$1;
    }
  } else if (match$1 === "") {
    return match;
  } else {
    return match + " " + match$1;
  }
}

function nameWithRank(profile) {
  return Prelude.default(name(profile), "") + ": " + toString(profile.rank);
}

function isTsiProfile(profile) {
  return profile.rank === "TSI";
}

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel",
          _1: vesselId
        };
}

function ofVessels(vesselIds) {
  return {
          TAG: "AnyOf",
          _0: "vessel",
          _1: vesselIds
        };
}

function make(user, vessel, first_name, last_name, avatars, rank, state) {
  return {
          id: ProfileId.make(),
          first_name: first_name,
          last_name: last_name,
          rank: rank,
          vessel: vessel,
          avatars: avatars,
          user: user,
          state: state
        };
}

function _getImages(wrapper, profiles) {
  return Prelude.$$Array.flatMap(profiles, (function (param) {
                  return param.avatars;
                })).map(function (param) {
              return wrapper({
                          id: param.id,
                          file: param.file,
                          thumbnail: param.thumbnail
                        });
            });
}

function getImages(extra) {
  return _getImages((function (i) {
                return {
                        TAG: "Cloud",
                        _0: i
                      };
              }), extra);
}

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Rank ,
  Ranks ,
  State ,
  Identifier ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  name ,
  nameWithRank ,
  isTsiProfile ,
  ofVessel ,
  ofVessels ,
  make ,
  _getImages ,
  getImages ,
}
/* deserFromJS Not a pure module */
