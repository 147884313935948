// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Dialog from "../../../libs/Dialog.js";
import * as LevelId from "../LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as TreeView from "./TreeView.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as KeepScrollLink from "../../common/views/KeepScrollLink.js";
import * as QuestionFilter from "./QuestionFilter.js";
import * as LevelRiskStatus from "../LevelRiskStatus.js";
import * as LevelQuestionsView from "./LevelQuestionsView.js";

function update(vnode) {
  var vesselId = VesselId.Route.param("vesselId");
  var levelId = LevelId.Route.param("levelId");
  var system = State__Memory.Current.get();
  var currentProfile = system.profile;
  var risksFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(LevelRiskStatus.tFromJs));
        }));
  var areasFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
          return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                          return Question.QuestionArea.fromJs(x);
                        }));
        }));
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.levels = Level.root(vesselId), newrecord)), (function (param) {
          var root = Prelude.$$Array.first(param.levels);
          var rootId = root !== undefined ? root.id : LevelId.$$null;
          var currentLevelId = Caml_obj.notequal(levelId, LevelId.$$null) ? levelId : rootId;
          var newrecord = Caml_obj.obj_dup(Query.makeRead());
          Prelude.thenDo(Query.read((newrecord.levels = {
                      TAG: "Or",
                      _0: Level.childrenOf(currentLevelId),
                      _1: {
                        TAG: "Or",
                        _0: Level.chapters(vesselId),
                        _1: {
                          TAG: "Get",
                          _0: currentLevelId
                        }
                      }
                    }, newrecord)), (function (param) {
                  var levels = param.levels;
                  var currentLevel = Prelude.default(Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                                  return Caml_obj.equal(l.id, currentLevelId);
                                }))), Level.Defaults.$$null);
                  var newrecord = Caml_obj.obj_dup(Query.makeRead());
                  Prelude.thenDo(Query.read((newrecord.questions = Question.insideLevel(currentLevel.path), newrecord)), (function (param) {
                          var questions = Question.deduplicate(param.questions);
                          var questions$1 = Curry._2(Prelude.$$Array.keep, questions, (function (q) {
                                  if (Question.filterByQuestionArea(q, areasFilter)) {
                                    return Question.filterByQuestionRank(q, currentProfile);
                                  } else {
                                    return false;
                                  }
                                }));
                          var match = Curry._3(Prelude.$$Array.fold, Curry._1(Prelude.$$Array.concatMany, questions$1.map(function (q) {
                                        return Curry._2(Prelude.$$Array.keep, q.level_paths, (function (i) {
                                                      return i.startsWith(currentLevel.path);
                                                    }));
                                      })), [
                                [],
                                levels
                              ], (function (param, path) {
                                  var match = Curry._2(Prelude.$$Array.partition, param[1], (function (extra) {
                                          if (extra.path === path) {
                                            return true;
                                          } else {
                                            return path.startsWith(extra.path);
                                          }
                                        }));
                                  var branches = Curry._2(Prelude.$$Array.concat, param[0], match[0]);
                                  return [
                                          branches,
                                          match[1]
                                        ];
                                }));
                          var levels$1 = match[0];
                          var chapter = Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, levels$1, (function (l) {
                                      if (l.depth === 2) {
                                        return currentLevel.path.startsWith(l.path);
                                      } else {
                                        return false;
                                      }
                                    })));
                          var children = Prelude.$$Array.sort(Curry._2(Prelude.$$Array.keep, levels$1, (function (l) {
                                      if (Caml_obj.equal(l.parent_id, Caml_option.some(currentLevelId))) {
                                        if (risksFilter !== undefined) {
                                          if (risksFilter.length !== 0) {
                                            if (Curry._1(Prelude.OptionExported.$$Option.isSome, l.risk_status)) {
                                              return Prelude.$$Array.includes(risksFilter, Curry._1(Prelude.OptionExported.$$Option.getExn, l.risk_status));
                                            } else {
                                              return false;
                                            }
                                          } else {
                                            return Curry._1(Prelude.OptionExported.$$Option.isNone, l.risk_status);
                                          }
                                        } else {
                                          return true;
                                        }
                                      } else {
                                        return false;
                                      }
                                    })), (function (l) {
                                  return l.number;
                                }), undefined);
                          Mithril.setState(vnode, {
                                TAG: "Loaded",
                                children: children,
                                vesselId: vesselId,
                                levelId: levelId,
                                chapter: chapter,
                                currentLevel: currentLevel,
                                questions: questions$1,
                                risksFilter: risksFilter,
                                areasFilter: areasFilter
                              });
                        }));
                }));
        }));
}

function make(param) {
  return Mithril.view(Mithril.onupdate(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                        update(vnode);
                      })), (function (vnode) {
                    var currentLevel = LevelId.Route.param("levelId");
                    var currentRisksFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("risks"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(LevelRiskStatus.tFromJs));
                          }));
                    var currentAreasFilter = Curry._2(Prelude.OptionExported.$$Option.map, Mithril$1.route.param("areas"), (function (p) {
                            return Prelude.$$Array.keepSome(p.split(",").map(function (x) {
                                            return Question.QuestionArea.fromJs(x);
                                          }));
                          }));
                    var match = vnode.state.data;
                    if (typeof match !== "object" || !(Caml_obj.notequal(currentLevel, match.levelId) || Caml_obj.notequal(match.risksFilter, currentRisksFilter) || Caml_obj.notequal(match.areasFilter, currentAreasFilter))) {
                      return ;
                    } else {
                      update(vnode);
                      return KeepScrollLink.restoreScroll(undefined);
                    }
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return React.jsx(ListLayout.make, {
                              children: Mithril.empty,
                              title: "SIRE 2.0",
                              showBackButton: true,
                              showSettings: false
                            });
                }
                var questions = match.questions;
                var currentLevel = match.currentLevel;
                var chapter = match.chapter;
                var levelId = match.levelId;
                var vesselId = match.vesselId;
                var children = match.children;
                var filterDialogId = "label-" + levelId;
                var fill = Prelude.$$Array.isEmpty(Prelude.default(match.areasFilter, [])) && Prelude.$$Array.isEmpty(Prelude.default(match.risksFilter, [])) ? undefined : "var(--color-primary)";
                var docId = currentLevel.guidance_document_id;
                return React.jsxs(ListLayout.make, {
                            children: [
                              ReactDOM.jsx("div", {
                                    children: React.jsxKeyed(QuestionFilter.make, {
                                          id: filterDialogId
                                        }, filterDialogId, undefined)
                                  }),
                              children.length !== 0 ? children.map(function (child) {
                                      var subtitle = Level.getOverdueText(child, questions);
                                      var url = URLs.vesselLevel(vesselId, child.id);
                                      if (Prelude.$$Array.isEmpty(Curry._2(Prelude.$$Array.keep, questions, (function (q) {
                                                    return !Prelude.$$Array.isEmpty(Curry._2(Prelude.$$Array.keep, q.level_paths, (function (path) {
                                                                      return path.startsWith(child.path);
                                                                    })));
                                                  })))) {
                                        return ReactDOM.jsxKeyed("div", {}, child.id, undefined);
                                      } else {
                                        return React.jsxKeyed(TileLink.make, {
                                                    title: child.name,
                                                    href: url,
                                                    subtitle: subtitle,
                                                    action: Caml_option.some(React.jsx(TreeView.Completion.make, {
                                                              level: child
                                                            }))
                                                  }, child.id, undefined);
                                      }
                                    }) : ReactDOM.jsx("div", {
                                      children: React.jsxKeyed(LevelQuestionsView.make, {
                                            vesselId: vesselId,
                                            levelId: levelId
                                          }, levelId, undefined)
                                    })
                            ],
                            title: chapter !== undefined ? chapter.name : currentLevel.name,
                            showBackButton: true,
                            actions: docId !== undefined ? [
                                {
                                  icon: "map",
                                  effect: {
                                    TAG: "Link",
                                    _0: URLs.markdownDocument(Caml_option.valFromOption(docId))
                                  }
                                },
                                {
                                  icon: "filter",
                                  effect: {
                                    TAG: "Fn",
                                    _0: (function (param) {
                                        Dialog.openDialog(vnode, filterDialogId);
                                      })
                                  },
                                  fill: fill
                                }
                              ] : [{
                                  icon: "filter",
                                  effect: {
                                    TAG: "Fn",
                                    _0: (function (param) {
                                        Dialog.openDialog(vnode, filterDialogId);
                                      })
                                  },
                                  fill: fill
                                }]
                          });
              }));
}

export {
  update ,
  make ,
}
/* URLs Not a pure module */
