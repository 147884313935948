// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as PageHeader from "../../common/views/PageHeader.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../libs/Conditional.js";
import * as State__User from "../../state/State__User.js";
import * as UserProfile from "../UserProfile.js";
import * as ButtonAction from "../../common/components/ButtonAction.js";
import * as ProfileAvatar from "../components/ProfileAvatar.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as PageNavigation from "../../common/views/PageNavigation.js";
import * as FormInputProfile from "../components/FormInputProfile.js";
import * as TakePictureModal from "../../common/components/TakePictureModal.js";
import * as FormSelectProfile from "../components/FormSelectProfile.js";

function hasImage(image) {
  if (typeof image === "object") {
    return true;
  }
  switch (image) {
    case "Empty" :
    case "FailedAttempt" :
        return false;
    case "RankImage" :
        return true;
    
  }
}

function toAvatars(image) {
  if (typeof image !== "object") {
    return [];
  } else {
    return [image._0];
  }
}

function profileExists(profiles, userId, vesselId, firstName, lastName, rank) {
  return Prelude.$$Array.isNotEmpty(Curry._2(Prelude.$$Array.keep, profiles, (function (p) {
                    if (p.first_name.toLowerCase() === firstName.toLowerCase() && p.last_name.toLowerCase() === lastName.toLowerCase() && Caml_obj.equal(p.user, userId) && Caml_obj.equal(p.vessel, vesselId)) {
                      return p.rank === rank;
                    } else {
                      return false;
                    }
                  })));
}

function saveNewProfile(vnode, nextStep) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  }
  var rank = match.rank;
  var image = match.image;
  var vessel = match.vessel;
  var user = match.user;
  var profiles = match.profiles;
  var exit = 0;
  if (typeof image !== "object") {
    switch (image) {
      case "Empty" :
      case "FailedAttempt" :
          break;
      case "RankImage" :
          exit = 2;
          break;
      
    }
  } else {
    exit = 2;
  }
  if (exit === 2 && rank !== undefined) {
    var avatars = toAvatars(image);
    var name = match.name.trim();
    var lastName = match.lastName.trim();
    var exists = profileExists(profiles, user.id, vessel.id, name, lastName, rank);
    if (exists) {
      return Mithril.setState(vnode, {
                  TAG: "Loaded",
                  profiles: profiles,
                  user: user,
                  vessel: vessel,
                  image: image,
                  name: name,
                  lastName: lastName,
                  rank: rank,
                  exists: exists,
                  showModal: match.showModal
                });
    }
    var newProfile = UserProfile.make(user.id, vessel.id, name, lastName, avatars, rank, "active");
    var newrecord = Caml_obj.obj_dup(Query.makeWrite());
    return Prelude.thenDo(Query.write((newrecord.newUserProfiles = [{
                        TAG: "Save",
                        _0: newProfile
                      }], newrecord)), (function (param) {
                  State__Memory.Persisted.store(State__Memory.Transformations.run(State__Memory.Transformations.setCurrentProfile(State__Memory.Transformations.make(State__Memory.Current.get()), newProfile)));
                  if (nextStep === "continue") {
                    Mithril$1.route.set("/");
                  } else {
                    window.history.back();
                  }
                }));
  }
  console.error("Accounts_AddProfile", "Cannot save a profile without rank or image", (function (prim) {
          return prim.state.data;
        }));
}

function initialize(vnode) {
  var vesselId = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Mithril$1.route.param("vesselId"), "");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.newUserProfiles = "All", newrecord.userProfiles = "All", newrecord.vessels = {
              TAG: "Get",
              _0: vesselId
            }, newrecord)), (function (param) {
          var newUserProfiles = param.newUserProfiles;
          var userProfiles = param.userProfiles;
          var vessels = param.vessels;
          Prelude.thenDo(State__User.getCurrent(), (function (user) {
                  if (user !== undefined) {
                    var vessel = Prelude.$$Array.first(vessels);
                    var profiles = Curry._2(Prelude.$$Array.keep, userProfiles.concat(newUserProfiles), (function (p) {
                            return Caml_obj.equal(p.vessel, vessel.id);
                          }));
                    return Mithril.setState(vnode, {
                                TAG: "Loaded",
                                profiles: profiles,
                                user: user,
                                vessel: vessel,
                                image: "Empty",
                                name: "",
                                lastName: "",
                                rank: undefined,
                                exists: false,
                                showModal: false
                              });
                  }
                  State__User.logout();
                }));
        }));
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), initialize), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  var header = React.jsx(PageHeader.make, {
                        content: {
                          NAME: "Title",
                          VAL: "Add profile"
                        }
                      });
                  return React.jsx(PageLayout.make, {
                              header: Caml_option.some(header)
                            });
                }
                var exists = match.exists;
                var rank = match.rank;
                var lastName = match.lastName;
                var name = match.name;
                var image = match.image;
                var hasAllData = name.trim() !== "" && lastName.trim() !== "" && Curry._1(Prelude.OptionExported.$$Option.isSome, rank) && hasImage(image);
                var match$1 = State__Memory.Current.get();
                var is_crew = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, match$1.role, (function (r) {
                            return r === "crew";
                          })), true);
                var isSaveReady = !exists && hasAllData;
                var tmp;
                var exit = 0;
                if (typeof image !== "object") {
                  switch (image) {
                    case "Empty" :
                    case "FailedAttempt" :
                        exit = 1;
                        break;
                    case "RankImage" :
                        if (rank !== undefined) {
                          tmp = React.jsx(ProfileAvatar.make, {
                                style: "profilePage",
                                rank: rank
                              });
                        } else {
                          exit = 1;
                        }
                        break;
                    
                  }
                } else {
                  tmp = React.jsx(ProfileAvatar.make, {
                        style: "profilePage",
                        image: image._0
                      });
                }
                if (exit === 1) {
                  tmp = React.jsx(ProfileAvatar.make, {
                        style: "profilePage"
                      });
                }
                return React.jsxs(PageLayout.make, {
                            header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                      content: {
                                        NAME: "Title",
                                        VAL: "Add profile"
                                      }
                                    })),
                            footer: Caml_option.some(React.jsx(PageNavigation.make, {
                                      disabled: true
                                    })),
                            children: [
                              React.jsx(TakePictureModal.make, {
                                    showModal: match.showModal,
                                    onClose: (function (_event) {
                                        var curState = vnode.state.data;
                                        if (typeof curState !== "object") {
                                          return ;
                                        } else {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      profiles: curState.profiles,
                                                      user: curState.user,
                                                      vessel: curState.vessel,
                                                      image: curState.image,
                                                      name: curState.name,
                                                      lastName: curState.lastName,
                                                      rank: curState.rank,
                                                      exists: curState.exists,
                                                      showModal: false
                                                    });
                                        }
                                      }),
                                    setImage: (function (extra, extra$1, extra$2) {
                                        return Prelude.thenDo(extra$2, (function (param) {
                                                      var curState = vnode.state.data;
                                                      if (typeof curState !== "object") {
                                                        return ;
                                                      } else {
                                                        return Mithril.setState(vnode, {
                                                                    TAG: "Loaded",
                                                                    profiles: curState.profiles,
                                                                    user: curState.user,
                                                                    vessel: curState.vessel,
                                                                    image: {
                                                                      TAG: "TakenImage",
                                                                      _0: extra$1
                                                                    },
                                                                    name: curState.name,
                                                                    lastName: curState.lastName,
                                                                    rank: curState.rank,
                                                                    exists: curState.exists,
                                                                    showModal: false
                                                                  });
                                                      }
                                                    }));
                                      }),
                                    setRankImage: (function (_event) {
                                        var curState = vnode.state.data;
                                        if (typeof curState !== "object") {
                                          return ;
                                        } else if (Curry._1(Prelude.OptionExported.$$Option.isNone, curState.rank)) {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      profiles: curState.profiles,
                                                      user: curState.user,
                                                      vessel: curState.vessel,
                                                      image: "FailedAttempt",
                                                      name: curState.name,
                                                      lastName: curState.lastName,
                                                      rank: curState.rank,
                                                      exists: curState.exists,
                                                      showModal: false
                                                    });
                                        } else {
                                          return Mithril.setState(vnode, {
                                                      TAG: "Loaded",
                                                      profiles: curState.profiles,
                                                      user: curState.user,
                                                      vessel: curState.vessel,
                                                      image: "RankImage",
                                                      name: curState.name,
                                                      lastName: curState.lastName,
                                                      rank: curState.rank,
                                                      exists: curState.exists,
                                                      showModal: false
                                                    });
                                        }
                                      }),
                                    hasImage: hasImage(image)
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(FormSelectProfile.make, {
                                            id: "rank",
                                            title: "Rank",
                                            onChange: (function (extra) {
                                                var value = extra.target.value;
                                                if (value === undefined) {
                                                  return ;
                                                }
                                                var value$1 = Caml_option.valFromOption(value);
                                                var match = vnode.state.data;
                                                if (typeof match !== "object") {
                                                  return ;
                                                }
                                                var lastName = match.lastName;
                                                var name = match.name;
                                                var vessel = match.vessel;
                                                var user = match.user;
                                                var profiles = match.profiles;
                                                var exists = profileExists(profiles, user.id, vessel.id, name, lastName, UserProfile.Rank.fromString(value$1));
                                                return Mithril.setState(vnode, {
                                                            TAG: "Loaded",
                                                            profiles: profiles,
                                                            user: user,
                                                            vessel: vessel,
                                                            image: match.image,
                                                            name: name,
                                                            lastName: lastName,
                                                            rank: UserProfile.Rank.fromString(value$1),
                                                            exists: exists,
                                                            showModal: match.showModal
                                                          });
                                              }),
                                            errorMessage: image === "FailedAttempt" ? "(please select rank)" : undefined,
                                            options: UserProfile.Ranks.sortedAll.filter(function (p) {
                                                    if (is_crew) {
                                                      return p !== "TSI";
                                                    } else {
                                                      return true;
                                                    }
                                                  }).map(function (r) {
                                                  return {
                                                          value: UserProfile.Rank.toString(r),
                                                          selected: Caml_obj.equal(rank, r)
                                                        };
                                                }),
                                            default: {
                                              value: "",
                                              selected: Curry._1(Prelude.OptionExported.$$Option.isNone, rank),
                                              displayVal: "Select rank",
                                              disabled: true
                                            },
                                            className: "tw-mt-3"
                                          }),
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsx("div", {
                                                    children: tmp,
                                                    className: "tw-h-[180px] tw-w-[180px] tw-m-1"
                                                  }),
                                              React.jsx(ButtonAction.make, {
                                                    children: hasImage(image) ? "Edit photo" : "Add photo",
                                                    onClick: (function (_event) {
                                                        var curState = vnode.state.data;
                                                        if (typeof curState !== "object") {
                                                          return ;
                                                        } else {
                                                          return Mithril.setState(vnode, {
                                                                      TAG: "Loaded",
                                                                      profiles: curState.profiles,
                                                                      user: curState.user,
                                                                      vessel: curState.vessel,
                                                                      image: curState.image,
                                                                      name: curState.name,
                                                                      lastName: curState.lastName,
                                                                      rank: curState.rank,
                                                                      exists: curState.exists,
                                                                      showModal: true
                                                                    });
                                                        }
                                                      })
                                                  })
                                            ],
                                            className: Mithril.classList([
                                                  "tw-flex tw-flex-col tw-gap-0.5 tw-items-center tw-mt-2.5 tw-mx-0",
                                                  "tw-mb-3"
                                                ], undefined)
                                          }),
                                      React.jsx(FormInputProfile.make, {
                                            title: "First Name",
                                            id: "first-name",
                                            value: name,
                                            placeholder: "Enter first name",
                                            onInput: (function (extra) {
                                                var value = extra.target.value;
                                                if (value === undefined) {
                                                  return ;
                                                }
                                                var value$1 = Caml_option.valFromOption(value);
                                                var match = vnode.state.data;
                                                if (typeof match !== "object") {
                                                  return ;
                                                }
                                                var rank = match.rank;
                                                var lastName = match.lastName;
                                                var vessel = match.vessel;
                                                var user = match.user;
                                                var profiles = match.profiles;
                                                var exists = profileExists(profiles, user.id, vessel.id, value$1, lastName, rank);
                                                return Mithril.setState(vnode, {
                                                            TAG: "Loaded",
                                                            profiles: profiles,
                                                            user: user,
                                                            vessel: vessel,
                                                            image: match.image,
                                                            name: value$1,
                                                            lastName: lastName,
                                                            rank: rank,
                                                            exists: exists,
                                                            showModal: match.showModal
                                                          });
                                              })
                                          }),
                                      React.jsx(FormInputProfile.make, {
                                            title: "Last Name",
                                            id: "last-name",
                                            value: lastName,
                                            placeholder: "Enter last name",
                                            onInput: (function (extra) {
                                                var value = extra.target.value;
                                                if (value === undefined) {
                                                  return ;
                                                }
                                                var value$1 = Caml_option.valFromOption(value);
                                                var match = vnode.state.data;
                                                if (typeof match !== "object") {
                                                  return ;
                                                }
                                                var rank = match.rank;
                                                var name = match.name;
                                                var vessel = match.vessel;
                                                var user = match.user;
                                                var profiles = match.profiles;
                                                var exists = profileExists(profiles, user.id, vessel.id, name, value$1, rank);
                                                return Mithril.setState(vnode, {
                                                            TAG: "Loaded",
                                                            profiles: profiles,
                                                            user: user,
                                                            vessel: vessel,
                                                            image: match.image,
                                                            name: name,
                                                            lastName: value$1,
                                                            rank: rank,
                                                            exists: exists,
                                                            showModal: match.showModal
                                                          });
                                              }),
                                            className: "tw-mt-2"
                                          }),
                                      React.jsx(Conditional.make, {
                                            cond: exists,
                                            children: ReactDOM.jsx("div", {
                                                  children: "This profile already exists.",
                                                  className: "tw-text-error"
                                                })
                                          })
                                    ],
                                    className: "tw-flex tw-flex-col tw-px-3"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "tw-grow"
                                  }),
                              ReactDOM.jsxs("div", {
                                    children: [
                                      React.jsx(ButtonAction.make, {
                                            children: "Save and return to profile selection",
                                            onClick: (function (_event) {
                                                saveNewProfile(vnode, "profileSelection");
                                              }),
                                            style: isSaveReady ? "default" : "hidden"
                                          }),
                                      React.jsx(ButtonAction.make, {
                                            children: isSaveReady ? "Save and continue" : "Save",
                                            onClick: (function (_event) {
                                                saveNewProfile(vnode, "continue");
                                              }),
                                            disabled: !isSaveReady,
                                            style: isSaveReady ? "primary" : "gray"
                                          })
                                    ],
                                    className: Mithril.classList([
                                          "tw-flex tw-flex-col tw-justify-end tw-gap-3 tw-min-h-[130px]",
                                          "tw-pt-5 tw-px-3 tw-pb-4"
                                        ], undefined)
                                  })
                            ]
                          });
              }));
}

export {
  make ,
}
/* Query Not a pure module */
