// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../Answer.js";
import * as Feather from "../../common/views/Feather.js";
import * as LevelId from "../LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as Completion from "../../common/Completion.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as QuestionId from "../QuestionId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LevelSubmission from "../LevelSubmission.js";
import * as ExpectedSubmission from "../../company_forms/ExpectedSubmission.js";
import * as LevelSubmissionManager from "../LevelSubmissionManager.js";

function completion(status) {
  var icon = status === "overdue" || status === "due" ? "circle" : "check_circle";
  var color = Completion.Color.getStatusColor(status);
  return React.jsx(Feather.make, {
              icon: icon,
              className: "text-" + color
            });
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var expectedSubmission = vnode.attrs.expectedSubmission;
                    var level = vnode.attrs.level;
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.questions = Question.insideLevel(level.path), newrecord)), (function (param) {
                            var submission = expectedSubmission.submission;
                            if (submission !== undefined) {
                              return Mithril.setState(vnode, {
                                          TAG: "Loaded",
                                          draftSubmission: submission,
                                          level: level,
                                          isCompleted: Question.deduplicate(param.questions).length <= submission.answers.length
                                        });
                            } else {
                              return Prelude.thenDo(LevelSubmissionManager.createDraftSubmission(level, Caml_option.some(Caml_option.some(expectedSubmission.start_date)), undefined), (function (submission) {
                                            Mithril.setState(vnode, {
                                                  TAG: "Loaded",
                                                  draftSubmission: submission,
                                                  level: level,
                                                  isCompleted: false
                                                });
                                          }));
                            }
                          }));
                  })), (function (vnode) {
                var expectedSubmission = vnode.attrs.expectedSubmission;
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var isCompleted = match.isCompleted;
                var level = match.level;
                var completionStatus = isCompleted ? "completed" : Completion.Status.fromCurrentDate(expectedSubmission.start_date, expectedSubmission.end_date);
                return React.jsx(TileLink.make, {
                            disabled: isCompleted,
                            title: ExpectedSubmission.name(expectedSubmission),
                            href: URLs.levelSubmission(level.vessel_id, level.id, Caml_option.some(Caml_option.some(match.draftSubmission.id)), Caml_option.some(Caml_option.some(expectedSubmission.start_date)), undefined),
                            subtitle: ExpectedSubmission.dateRange(expectedSubmission),
                            action: Caml_option.some(completion(completionStatus))
                          });
              }));
}

var LevelExpectedSubmissionTile = {
  completion: completion,
  make: make
};

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var submission = vnode.attrs.submission;
                return React.jsx(TileLink.make, {
                            disabled: true,
                            title: submission.target_date.toDateString(),
                            subtitle: submission.target_date.toLocaleTimeString(),
                            action: Caml_option.some(React.jsx(Feather.make, {
                                      icon: "check_circle",
                                      className: "text-success"
                                    }))
                          });
              }));
}

var PreviousSubmissionTile = {
  make: make$1
};

function make$2(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var submission = vnode.attrs.submission;
                    var path = vnode.attrs.level.path;
                    var newrecord = Caml_obj.obj_dup(Query.makeRead());
                    Prelude.thenDo(Query.read((newrecord.newAnswers = {
                                TAG: "And",
                                _0: {
                                  TAG: "In",
                                  _0: submission.answers
                                },
                                _1: Answer.insideLevel(path)
                              }, newrecord.answers = {
                                TAG: "And",
                                _0: {
                                  TAG: "In",
                                  _0: submission.answers
                                },
                                _1: Answer.insideLevel(path)
                              }, newrecord.questions = Question.insideLevel(path), newrecord)), (function (param) {
                            Mithril.setState(vnode, {
                                  TAG: "Loaded",
                                  questions: Question.deduplicate(param.questions),
                                  answers: Curry._2(Prelude.$$Array.concat, param.answers, param.newAnswers)
                                });
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var qCount = match.questions.length;
                var aCount = QuestionId.$$Set.toArray(QuestionId.$$Set.fromArray(match.answers.map(function (a) {
                              return a.question_id;
                            }))).length;
                return ReactDOM.jsx("div", {
                            children: qCount !== aCount ? ReactDOM.jsx("div", {
                                    children: String(aCount) + "/" + String(qCount)
                                  }) : React.jsx(Feather.make, {
                                    icon: "check_circle",
                                    className: "text-success",
                                    size: 22
                                  }),
                            className: "text-center"
                          });
              }));
}

var LevelCompletion = {
  make: make$2
};

function make$3(param) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    var level = vnode.attrs.level;
                    Prelude.thenDo(LevelSubmissionManager.getCompletion(level), (function (completionInfo) {
                            Mithril.setState(vnode, {
                                  TAG: "Loaded",
                                  completionInfo: completionInfo
                                });
                          }));
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                } else {
                  return React.jsx(TileLink.make, {
                              title: vnode.attrs.submission.name,
                              href: URLs.levelSubmission(vnode.attrs.level.vessel_id, vnode.attrs.level.id, Caml_option.some(Caml_option.some(vnode.attrs.submission.id)), Caml_option.some(Caml_option.some(vnode.attrs.submission.timestamp)), undefined),
                              subtitle: vnode.attrs.submission.target_date.toUTCString(),
                              action: Caml_option.some(React.jsx(make$2, {
                                        level: vnode.attrs.level,
                                        submission: vnode.attrs.submission
                                      }))
                            });
                }
              }));
}

var DraftSubmissionTile = {
  make: make$3
};

function load(vnode) {
  var levelId = LevelId.Route.param("levelId");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Prelude.thenResolve(Query.read((newrecord.draftLevelSubmissions = LevelSubmission.ofLevel(levelId), newrecord.levelSubmissions = LevelSubmission.ofLevel(levelId), newrecord.levels = {
                  TAG: "Get",
                  _0: levelId
                }, newrecord)), (function (param) {
              var draftLevelSubmissions = param.draftLevelSubmissions;
              var levelSubmissions = param.levelSubmissions;
              var draftLevelSubmissionsIds = draftLevelSubmissions.map(function (s) {
                    return s.id;
                  });
              var level = Prelude.$$Array.first(param.levels);
              if (level !== undefined) {
                var expectedSubmissions = Curry._1(Prelude.$$Array.reverse, Level.expectedSubmissions(level, Prelude.$$Array.sort(Curry._1(Prelude.$$Array.concatMany, [
                                  levelSubmissions,
                                  draftLevelSubmissions
                                ]), LevelSubmission.byTargetDate, undefined), draftLevelSubmissionsIds));
                var submissions = Prelude.$$Array.sort(levelSubmissions, LevelSubmission.byTargetDate, true);
                var draftSubmissions = Prelude.$$Array.sort(draftLevelSubmissions, LevelSubmission.byTargetDate, true);
                return {
                        TAG: "Loaded",
                        level: level,
                        expectedSubmissions: expectedSubmissions,
                        submissions: submissions,
                        draftSubmissions: draftSubmissions
                      };
              }
              window.history.back();
              return "NotReady";
            })), (function (extra) {
          return Mithril.setState(vnode, extra);
        }));
}

function createDraftSubmission(vnode, _event) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    window.history.back();
    return ;
  }
  var level = match.level;
  Prelude.thenDo(LevelSubmissionManager.createDraftSubmission(level, undefined, undefined), (function (draftSubmission) {
          Mithril$1.route.set(URLs.levelSubmission(level.vessel_id, level.id, Caml_option.some(Caml_option.some(draftSubmission.id)), undefined, undefined));
        }));
}

function title(vnode) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return "";
  } else {
    return match.level.name;
  }
}

function LevelSubmissionView(props) {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), load), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var draftSubmissions = match.draftSubmissions;
                  var submissions = match.submissions;
                  var level = match.level;
                  tmp = Curry._1(Prelude.OptionExported.$$Option.isSome, level.recurrence) ? match.expectedSubmissions.map(function (expectedSubmission) {
                          return React.jsxKeyed(make, {
                                      level: level,
                                      expectedSubmission: expectedSubmission
                                    }, String(expectedSubmission.id), undefined);
                        }) : ReactDOM.jsxs("div", {
                          children: [
                            draftSubmissions.length !== 0 ? ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("h5", {
                                            children: "New Submissions"
                                          }),
                                      draftSubmissions.map(function (submission) {
                                            return React.jsx(make$3, {
                                                        level: level,
                                                        submission: submission
                                                      });
                                          })
                                    ]
                                  }) : Mithril.empty,
                            submissions.length !== 0 ? ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("h5", {
                                            children: "Completed Submissions"
                                          }),
                                      submissions.map(function (submission) {
                                            return React.jsx(make$1, {
                                                        submission: submission
                                                      });
                                          })
                                    ]
                                  }) : Mithril.empty
                          ]
                        });
                }
                var match$1 = vnode.state.data;
                var tmp$1;
                if (typeof match$1 !== "object") {
                  tmp$1 = Mithril.empty;
                } else {
                  var match$2 = match$1.level.recurrence;
                  tmp$1 = match$2 !== undefined ? Mithril.empty : ReactDOM.jsx("button", {
                          children: "New submission",
                          className: "btn btn-primary btn-block btn-lg",
                          onClick: (function (extra) {
                              return createDraftSubmission(vnode, extra);
                            })
                        });
                }
                return React.jsx(ListLayout.make, {
                            children: tmp,
                            title: title(vnode),
                            bottom: Caml_option.some(tmp$1)
                          });
              }));
}

var make$4 = LevelSubmissionView;

export {
  LevelExpectedSubmissionTile ,
  PreviousSubmissionTile ,
  LevelCompletion ,
  DraftSubmissionTile ,
  load ,
  createDraftSubmission ,
  title ,
  make$4 as make,
}
/* URLs Not a pure module */
